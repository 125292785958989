export const menu = [
  {
    title: "About",
    url: "/",
    selected: false,
  },
  {
    title: "Work",
    url: "/work",
    selected: false,
  },
  {
    title: "Skills",
    url: "/skill",
    selected: false,
  },
  {
    title: "Contact",
    url: "/contact",
    selected: false,
  },
];

export const skills = [
  [
    "React.js",
    "Next.js",
    "Vue.js",
    "Angular.js",
    "Three.js",
    "TailwindCSS",
    "MaterialUI",
    "Bootstrap",
    "UI/UX Design",
    "Sass",
    "ESLint",
  ],
  [
    "Node.js",
    "Express.js",
    "Nest.js",
    "PHP",
    "Python",
    "MongoDB",
    "MySQL",
    "GraphQL",
    "Firebase",
    "AWS",
    "Azure",
  ],
  [
    "Rust",
    "Solidity",
    "Typescript",
    ".NET",
    "Java",
    "Ruby on Rail",
    "C++",
    "C#",
  ],
  [
    "Smart Contact",
    "Secret",
    "Crypto games",
    "Web3",
    "Cross-chain",
    "DeFi",
    "Trading App",
  ],
  ["Figma", "Unity", "Github", "Cloud Services"],
];

export const projects = [
  {
    id: 15,
    title: "Solana Coinflip game",
    description: "Coinflip casino game built on Solana blockchain",
    stack: ["Rust", "Anchor", "Solana", "Web3", "Anchor", "React"],
    image: "/img/15.png",
    links: [
      {
        link: "https://flip.is",
        id: 2,
      },
    ],
  },
  {
    id: 0,
    title: "Bozo chat",
    description: "AI chat website",
    stack: ["Next.js", "TailwindCSS", "Soul engine"],
    image: "/img/8.png",
    links: [
      {
        link: "https://chat.officialbozo.wtf/",
        id: 2,
      },
    ],
  },

  // {
  //   id: 1,
  //   title: 'Pioneer Legends',
  //   description: 'P2E game on solana chain earning by staking.',
  //   stack: ['Next.js', 'TypeScript', 'MongoDB', 'Rust', 'Solana web3'],
  //   image: '/img/2.png',

  //   links: [
  //     {
  //       link: 'https://www.pioneerlegends.io/',
  //       id: 1,
  //     },
  //   ],
  // },

  {
    id: 14,
    title: "Telegram Raydium Volume Bot",
    description: "Telegram Raydium Volume Bot",
    stack: ["Node", "node-telegram-bot-api"],
    image: "/img/14.png",
    links: [
      {
        link: "https://t.me/volume_boost_sol_bot",
        id: 2,
      },
    ],
  },

  {
    id: 9,
    title: "FlareGods",
    description:
      "FlareGods NFT mint platform, unique and intricately designed Flaregods warriors, Predators & Cerebrus-X Apes.",
    stack: ["Next.js", "Rust", "Solana Web3"],
    image: "/img/10.png",
    links: [
      {
        link: "https://flaregods.xyz/",
        id: 2,
      },
    ],
  },

  // {
  //   id: 2,
  //   title: "$Bmp brc-20",
  //   description: "$bmp token(Bitcoin BRC-20) airdrop platform to",
  //   stack: ["React", "Brc-20"],
  //   image: "/img/3.png",
  //   links: [
  //     {
  //       link: "https://bmpbrc.com/",
  //       id: 2,
  //     },
  //   ],
  // },
  {
    id: 3,
    title: "Duel Arena",
    description: "Blackjack solana p2e game",
    stack: ["Next.js", "Node.js", "MongoDB", "Solana web3"],
    image: "/img/4.png",
    links: [
      {
        link: "https://duelarena.io/",
        id: 2,
      },
    ],
  },
  {
    id: 10,
    title: "Satoshi Punks",
    description: "Ordinal raffle platform in Bitcoin Network.",
    stack: ["Next.js", "Typescrit", "Ordinals"],
    image: "/img/11.png",
    links: [
      {
        link: "https://www.satoshipunks.art/",
        id: 2,
      },
    ],
  },
  {
    id: 11,
    title: "Bitcoin Defi",
    description: "ArcusLab is a cutting-edge Bitcoin DeFi platform",
    stack: ["Next.js", "Typescrit", "BitcoinJS", "MongoDB"],
    image: "/img/12.png",
    links: [
      {
        link: "https://arcusbtc.com",
        id: 2,
      },
    ],
  },

  {
    id: 5,
    title: "Blood loop",
    description: "NFT Staking platform on Avalance",
    stack: ["React", "Solidity", "TailwindCSS", "Web3"],
    image: "/img/6.png",
    links: [
      {
        link: "https://app.bloodloop.com/",
        id: 2,
      },
    ],
  },
  {
    id: 7,
    title: "Convesting",
    description: "Powerful tools for wallets, exchanges and trading platform",
    stack: ["Next.js", "TypeScript", "Solidity", "Web3"],
    image: "/img/1.png",
    links: [
      {
        link: "https://covesting.io/",
        id: 2,
      },
    ],
  },
  {
    id: 4,
    title: "Pick Melf",
    description: "shopping platform",
    stack: ["Wordpress", "MySQL"],
    image: "/img/5.png",
    links: [
      {
        link: "https://www.picknmelt.com/",
        id: 2,
      },
    ],
  },
  {
    id: 8,
    title: "Gamez",
    description:
      "Platform for games like Baccarat, Roulette, Andar Bahar, Sic Bo, Dragon tiger",
    stack: ["Next.js", "Express", "Figma"],
    image: "/img/9.png",
    links: [
      {
        link: "https://kasagames.com/",
        id: 2,
      },
    ],
  },
  {
    id: 6,
    title: "First for support",
    description: "SPECIALIST CARE Recruitment & Consultancy Services",
    stack: ["React", "MaterialUI", "Typescript", "Express", "MongoDB", "AWS"],
    image: "/img/7.png",
    links: [
      {
        link: "https://firstforsupport.co.uk/",
        id: 2,
      },
    ],
  },
];
